
import store from '@/store';
import axios from 'axios';
import { message } from './message';
import router from '@/router';


// 创建一个axios实例
const request = axios.create({  // https://api.car.scxyjnet.com/cashier
  baseURL: 'https://api.car.scxyjnet.com/cashier', // 正式设置基本URL
  // baseURL: 'https://carapi.scxyjnet.com/cashier', // 测试设置基本URL

  timeout: 50000, // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以做一些处理，例如添加token到请求头
    const token = store.state.user.token
    // console.log(token);
    if(token) {
      config.headers.token = `${token}`;
    }
    // console.log(config);
    return config;
   },
   (error) => {
    // 请求错误处理
   
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // 在这里对响应进行处理
    // console.log(response.data);
    if(response.data.status  == 2) {
        message(response.data.msg, 'error')
        router.push('login')
        store.commit('user/delToken')
    }
    return response.data;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export default request ;